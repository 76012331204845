<template>
  <v-container fluid class="pa-0">
    <v-container class="d-flex flex-wrap justify-center align-start">
      <v-col cols="12 mb-5">
        <h1 class="page-title">Notícias</h1>
      </v-col>
      <v-col v-if="newsHome.length === 0" class="d-flex flex-column justify-center align-center" cols="12">
        <v-img src="../../../../assets/Pulse-0.7s-200px.svg" max-width="50"></v-img>
        <h5 class="pa-0">Carregando...</h5>
      </v-col>
      <v-col v-for="(card, index) in newsHome" :key="index" cols="12" sm="6" md="6" lg="4" xl="3">
        <v-card class="mb-10" max-width="100%"  outlined style="border:none">
          <a href="#" @click.prevent="openPost(card.id)">
            <div class="black box-image d-flex justify-center align-center">
              <v-img :src="card.url" height="200px" class="card-image"></v-img>
            </div>
          </a>

          <a href="#" @click.prevent="openPost(card.id)">
            <v-card-title class="title">{{card.title}}</v-card-title>
          </a>
          <a href="#" @click.prevent="openPost(card.id)">
            <v-card-subtitle class="subtitle">{{card.category}}</v-card-subtitle>
          </a>
        </v-card>
      </v-col>
      <v-row class="d-flex justify-center" v-if="countPages > 0">
        <v-col md="4" lg="4" xl="4" class="pa-5 d-flex justify-left">
          <v-pagination
            v-model="page"
            :length="countPages"
            @input="handlePaginationPageChange"
          />
        </v-col>
      </v-row>
      <!-- <v-col cols="12" class="pa-5" sm="6" md="4" lg="4" xl="4" v-for="(card, index) in newsHome" :key="index" >
        <card :title="card.title" :id="card.id" :category="card.category" :file="{}" :url="card.url" @open="openPost($event)" :height="238"/>
      </v-col>-->
    </v-container>
  </v-container>
</template>

<script>
import newsService from "../../../../service/newsService";

export default {
  name: "NewsHome",
   metaInfo: {
    title: "SEEB | Notícias",
     meta: [
      { name: 'description', content: 'Notícias e comunicados do sindicato do bancarios de Uberlândia' }
    ]

  },
  methods: {
    openPost(id) {
      // this.$router.push({ path: `news/post/${id}` })
      // this.$router.push({
      //   name: "Post",
      //   params: { id: id, text: "Notícias", href: "/news", type: "news" }
      // });
    this.$router.push({ path: `/post/news/${id}`})
    },
    getNews() {
      newsService.paginate({}, this.page).then(res => {
        const templateResponse = res.data;
        this.countPages = templateResponse.countPages;

        this.newsHome = templateResponse.data.map(news => {
          return {
            id: news.id,
            title: news.title,
            category: news.category,
            date: new Date(news.created_at).toLocaleString(),
            url: news.path_img_card
          };
        });
      });
    },
    handlePaginationPageChange() {
      this.getNews();
    },
  },
  mounted() {
    this.getNews();
  },
  components: {
    Card: () => import("../../../../components/commons/Card"),
    Footer: () => import("@/layouts/web/commons/Footer.vue")
  },
  data() {
    return {
      newsHome: [],
      page: 1,
      countPages: 0,
    };
  }
};
</script>

<style lang="sass" scoped>
  a
    text-decoration: none

  .box-image
    background: #0b0b0b
    display: block
    height: 200px
    min-height: 200px
    border-radius: .3125rem
    overflow: hidden

  .card-image
    border-radius: .3125rem
    opacity: .9
    transition:
      property: opacity
      duration: .2s
      transition-timing-function: ease-in-out

    &:hover
      opacity: .6

  .page-title
    background: #114979
    color: #fff
    font-family: Raleway,sans-serif
    font-weight: 700
    line-height: 1
    margin: 0
    font-size: 1.75rem
    padding: 1.25rem 1.25rem

  .subtitle
    text-transform: uppercase
    font-size: .75rem
    line-height: 1.167
    font-weight: 500
    letter-spacing: -.03em
    margin-bottom: .5rem
    color: #262626
    transition:
      property: all
      duration: .2s
      transition-timing-function: ease-in-out

    &:hover
      color: #2196F3

  .title
    font-size: 1.25rem
    line-height: 1.25
    font-weight: 800
    margin-bottom: 0
    color: #000
    transition:
      property: all
      duration: .2s
      transition-timing-function: ease-in-out

    &:hover
      color: #2196F3

  .v-application .title
    margin-left: 0
</style>
